import { CharDataType } from "rl-common/consts";

export interface CharDataTypeMeta {
	displayName: string;
	editElementType: string;
}

export namespace CharDataTypeUtil {
	export const CharDataTypeMetaLookup: { [key in CharDataType]: CharDataTypeMeta } = {
		[CharDataType.FourDigitYear]: {
			displayName: "Year",
			editElementType: "year"
		},
		[CharDataType.InternetAddress]: {
			displayName: "Link",
			editElementType: "link"
		},
		[CharDataType.Alphanumeric]: {
			displayName: "Alpha Numeric",
			editElementType: "text"
		},
		[CharDataType.Number]: {
			displayName: "Number",
			editElementType: "number"
		},
		[CharDataType.Date]: {
			displayName: "Date",
			editElementType: "date"
		},
		[CharDataType.Email]: {
			displayName: "Email",
			editElementType: "text"
		},
		[CharDataType.Currency]: {
			displayName: "Currency",
			editElementType: "amount"
		},
		[CharDataType.Percentage]: {
			displayName: "Percentage",
			editElementType: "percentage"
		},
		[CharDataType.Password]: {
			displayName: "Password",
			editElementType: null // TODO: Did we implement this?
		},
		[CharDataType.SMPTETimeCode]: {
			displayName: "SMPTE Time Code",
			editElementType: "timecode"
		},
		[CharDataType.CreditCard]: {
			displayName: "Credit Card",
			editElementType: "creditcard"
		},
		[CharDataType.ExternalDocument]: {
			displayName: "External Document",
			editElementType: "file"
		},
		[CharDataType.Checkbox]: {
			displayName: "Checkbox",
			editElementType: "checkbox"
		},
		[CharDataType.Money]: {
			displayName: "Money",
			editElementType: "money"
		},
		[CharDataType.KeyPair]: {
			displayName: "Key Pair",
			editElementType: "text"
		},
		[CharDataType.DateRange]: {
			displayName: "Date Range",
			editElementType: "dateRange"
		},
		[CharDataType.NumberRange]: {
			displayName: "Number Range",
			editElementType: "numberRange"
		},
		[CharDataType.Period]: {
			displayName: "Period",
			editElementType: "Period"
		}
	};

	export function toDisplayName(charDataType: CharDataType) {
		return CharDataTypeMetaLookup[charDataType]?.displayName;
	}
}
